import React, { useContext, useState } from "react"
import { SpaceContext } from "@hooks/SpaceContext"
import SEO from "@components/seo"
import Layout from "@components/layout"
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap"
import { navigate } from "gatsby"
import { useAuth } from "@auth/useAuth"

const AddLocationPage = () => {
  const { makeRequest } = useAuth() || {}

  const [name, setName] = useState("")
  const [space_operator_id, setSp] = useState("")

  const {
    data: { locationsCategorizedBySpaceOperator },
  } = useContext(SpaceContext)

  const add = async () => {
    const res = await makeRequest("sp/add", { name, space_operator_id })
    if (res && res.space && res.space.id) navigate(`/location/${res.space.id}`)
  }

  return (
    <>
      <SEO title="Add new location" />
      <Layout
        title={"Add new location"}
        progress={locationsCategorizedBySpaceOperator.length <= 0}
      >
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label>Location's Name</Label>
              <Input
                type="text"
                placeholder="Full Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label>Space Operator</Label>
              <Input
                type="select"
                placeholder="Email"
                value={space_operator_id}
                onChange={(e) => setSp(e.target.value)}
              >
                <option value="" disabled>
                  Please select
                </option>
                {locationsCategorizedBySpaceOperator.map((sp) => (
                  <option value={sp.id} key={sp.id}>
                    {sp.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Button color={"success"} onClick={add}>
                Add
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Layout>
    </>
  )
}

export default AddLocationPage
